<template>
    <div class="container">
        <crud ref="crud" :options="options">
            <template slot="header">
                <el-form-item>
                    <el-input v-model="options.params.keyword" clearable placeholder="账号/昵称"/>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$refs.crud.getLists()" icon="el-icon-search">查找</el-button>
                </el-form-item>
            </template>
            <template slot="actions" slot-scope="scope">
                <el-button type="text" @click="resetPassword(scope.row)" icon="el-icon-refresh-right"
                           class="color-warning">密码重置
                </el-button>
            </template>
        </crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: true,
                actionWidth: 240,
                addUrl: 'admin.admin.add',
                addPerm: 'admin_admin_add',
                editUrl: 'admin.admin.edit',
                editPerm: 'admin_admin_edit',
                delUrl: 'admin.admin.delete',
                delPerm: 'admin_admin_delete',
                listUrl: 'admin.admin.page',
                formLabelWidth: '100px',
                params: {
                    keyword: null
                },
                columns: [
                    {
                        prop: "avatar",
                        label: "头像",
                        type: 'image',
                        width: 72,
                        html: ({row}) => {
                            return row.avatar ? `<img class="list-user-avatar" src="${row.avatar}" alt="加载失败"/>` : null
                        }
                    },
                    {prop: "nickname", label: "昵称", required: true},
                    {prop: "account", label: "账号", required: true},
                    {
                        prop: "gender",
                        label: "性别",
                        required: true,
                        type: "radio",
                        value: 1,
                        dicData: [{label: '男', value: 1}, {label: '女', value: 2}],
                        formatter: row => {
                            return row.gender === 1 ? '男' : '女'
                        }
                    },
                    {
                        prop: "role_id",
                        label: "用户角色",
                        type: "select",
                        required: true,
                        dicUrl: 'admin.role.list',
                        dicData: [],
                        dicProps: {label: 'name'},
                        formatter: row => {
                            return row.role ? row.role.name : null
                        }
                    },
                    {
                        prop: "site_id",
                        label: "所在站点",
                        type: "select",
                        required: false,
                        dicUrl: 'league.Site.list',
                        dicData: [],
                        dicProps: {label: 'name', value: 'id'},
                        formatter: row => {
                            return row.site ? row.site.name : '总站'
                        }
                    },
                    {
                        prop: "dept_id",
                        label: "所在部门",
                        type: "cascader",
                        required: true,
                        dicUrl: 'admin.dept.tree',
                        dicData: [],
                        dicProps: {label: 'name', value: 'id'},
                        formatter: row => {
                            return row.dept ? row.dept.name : null
                        }
                    },
                    {
                        prop: "post_id",
                        label: "所在岗位",
                        type: "select",
                        required: true,
                        dicUrl: 'admin.post.list',
                        dicData: [],
                        dicProps: {label: 'name'},
                        formatter: row => {
                            return row.post ? row.post.name : null
                        }
                    },
                    {prop: "remark", label: "备注",}
                ],
            }
        }
    },
    methods: {
        resetPassword: function (row) {
            this.$confirm('是否确认重置该账户密码？', '提示', {type: 'warning'}).then(() => {
                this.$helper._post(this, 'admin.admin.reset', {id: row.id}, resp => {
                    this.$message.success(resp.message)
                })
            }).catch(() => {
            });
        }
    }
}
</script>

<style scoped>

</style>
